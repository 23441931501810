<template>
  <div class="article">
    <a-modal v-model="PVWVisible" title="图片预览" :footer="null">
      <img
        :src="pvwImgUrl"
        style="maxWidth:100%;maxHeight:100%;margin:0 auto"
      />
    </a-modal>

    <a-modal v-model="visible" @ok="handleOk" okText="确定" cancelText="取消">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="反馈数量">
          <a-input-number :min="0" style="width:300px" placeholder="请输入拜访反馈数量" v-model="Num" />
        </a-form-item>
      </a-form>
    </a-modal>

    <div class="actionBar">
      <a-space>
        <CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="undefined"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>
        <GroupSelect @GroupSelect="GroupSelect"></GroupSelect>
        <a-range-picker
          style="width: 280px"
          v-model="time"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
          @change="timeChange"
        >
        </a-range-picker>
        <a-input-search
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 220px"
          allowClear
          @search="onSearch"
        />
      </a-space>

      <a-space>
        <a-button icon="plus" type="primary" @click="addVisit()" v-if="handLists.top.indexOf('add') != -1">
          新增拜访
        </a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="titles" slot-scope="text">
        {{ text }}
      </span>
      <span slot="list_order" slot-scope="text, record">
        <a-input-number
          :min="1"
          v-model="record.list_order"
          style="width:60px"
          @focus="saveOldNum(text)"
          @blur="AchangeVal(record.id, record.list_order)"
        >
        </a-input-number>
      </span>
      <span slot="img_url" slot-scope="text">
        <a v-if="text" @click="pvwImg(text)">
          <img :src="text" alt="" style="maxWidth:100px;maxHeight:100px" />
        </a>
        <span v-else>
          暂无
        </span>
      </span>
      <span slot="status" slot-scope="text, record">
        <a-popover placement="top" v-if="handLists.right.indexOf('setStatus') != -1">
          <template slot="content">
            {{ text == 1 ? "点击禁用" : "点击启用" }}
          </template>
          <a-tag
            :color="text == 1 ? 'green' : '#999'"
            @click="AsetStatus(record.id, text == 1 ? 2 : 1)"
          >
            {{ text == 1 ? "启用" : "禁用" }}
          </a-tag>
        </a-popover>

        <a-tag
            :color="text == 1 ? 'green' : '#999'"
            v-else
          >
            {{ text == 1 ? "启用" : "禁用" }}
          </a-tag>
      </span>

      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('brush') != -1">
           <FontIcon
            @click.native="brush(record.id)"
            title="刷拜访反馈"
            type="icon-add-fill"
            size="22"
          ></FontIcon>
           <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('edit') != -1">
            <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
        ></FontIcon>
        <a-divider type="vertical" />
        </span>
      
        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  visitIndex,
  visitDelete,
  visitSetStatus,
  visitChangeVal,
  visitBackBrushBack
} from "@/apis/index";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import { watch } from "fs";
const columns = [
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
  },
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "拜访名称",
    dataIndex: "title",
    scopedSlots: { customRender: "titles" },
  },
  {
    title: "产品名称",
    dataIndex: "product",
    scopedSlots: { customRender: "product" },
  },
  {
    title: "简介",
    dataIndex: "desc",
    scopedSlots: { customRender: "desc" },
  },

  {
    title: "开始时间",
    dataIndex: "start_time",
    scopedSlots: { customRender: "start_time" },
    customRender: (text, record) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    width: 110,
  },
  {
    title: "结束时间",
    dataIndex: "end_time",
    scopedSlots: { customRender: "end_time" },
    customRender: (text, record) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    width: 110,
  },

  {
    title: "触达人数",
    dataIndex: "send_count",
    scopedSlots: { customRender: "send_count" },
    width: 90,
  },
  {
    title: "反馈人数",
    dataIndex: "back_count",
    scopedSlots: { customRender: "back_count" },
    width: 90,
  },
  {
    title: "栏目名称",
    dataIndex: "group",
    scopedSlots: { customRender: "group" },
    customRender: (text, record) => {
      if (text) {
        return text.name;
      } else {
        return "";
      }
    },
    width: 90,
  },
  {
    title: "企业名称",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    customRender: (text, record) => {
      if (text) {
        return text.company_name;
      } else {
        return "";
      }
    },
  },
  {
    title: "封面图",
    dataIndex: "img_url",
    scopedSlots: { customRender: "img_url" },
  },
  {
    title: "设置状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    width: 90,
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 140,
  },
];
export default {
  data() {
    return {
      locale,
      columns,
      data: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        group_id: undefined,
        start_time: "",
        end_time: "",
        company_id: undefined,
      },
      count: 0,
      time: [],

      oldNum: "",
      PVWVisible: false,
      pvwImgUrl: "",
       handLists: this.$store.state.menuHandleList,

       visible: false,
      Num:0,
      surverId:'',
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getVisitIndex();
  },
  methods: {
    handleOk(){
      if(this.Num==0){
        this.$message.error('反馈数量必须大于0')
        return false
      }
      visitBackBrushBack({id:this.surverId,num:this.Num}).then(res=>{
        if(res.code==1){
          this.$message.success('成功')
          this.visible=false
        }
      })
    },
    brush(id){
      this.surverId=id
      this.visible=true
      this.Num=''
    },


    pvwImg(val) {
      this.pvwImgUrl = val;
      this.PVWVisible = true;
    },
    CompanySelect(val) {
      this.listParams.company_id = val;
      this.getVisitIndex();
    },

    timeChange(a) {
      if (a.length > 0) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
      this.getVisitIndex();
    },
    saveOldNum(val) {
      this.oldNum = val;
    },
    AchangeVal(id, num) {
      if (this.oldNum == num) {
        return false;
      } else {
        visitChangeVal({
          id,
          change_value: num,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
            this.getVisitIndex();
          }
        });
      }
    },
    GroupSelect(val) {
      this.listParams.group_id = val;
      this.getVisitIndex();
    },
    addVisit(id) {
      this.$router.push({
        path: "/datamaintenances/AddEditVisit",
      });
    },
 
    editMenu(record) {
      this.$router.push({
        path: "/datamaintenances/AddEditVisit",
        query: {
          id: record.id,
        },
      });
    },

    AsetStatus(id, val) {
      visitSetStatus({
        ids: id,
        status: val,
      }).then((res) => {
        if (res.code == 1) {
          this.getVisitIndex();
          this.$message.success("状态修改成功");
        }
      });
    },
    confirm(rec) {
      visitDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getVisitIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getVisitIndex();
    },
    getVisitIndex() {
      visitIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getVisitIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getVisitIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
